import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICountry } from '../models/shared.model';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SharedService {
  private readonly dataAPI: string = environment.apiUrl + 'Data/';

  constructor(private http: HttpClient) {}

  getCountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>(this.dataAPI + 'Countries');
  }
}
