import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ICountry } from '../models/shared.model';
import { GetCountries } from './shared.actions';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { produce } from 'immer';
import { SharedService } from '../services/shared.service';
import { HttpErrorResponse } from '@angular/common/http';

export class SharedStateModel {
  public countries: ICountry[];
}

const defaults = {
  countries: [],
};

@State<SharedStateModel>({
  name: 'shared',
  defaults,
})
@Injectable()
export class SharedState {
  @Selector() public static getCountries(state: SharedStateModel) {
    return state.countries;
  }

  constructor(private sharedService: SharedService) {}

  @Action(GetCountries)
  getCountries(ctx: StateContext<SharedStateModel>) {
    return this.sharedService.getCountries().pipe(
      tap((countries) => {
        const codes = ['DK', 'NO', 'SE'];

        const priorityCountries = countries.filter((x) => codes.includes(x.code));
        const filteredCountries = countries.filter((x) => !codes.includes(x.code));

        const newState: SharedStateModel = {
          ...ctx.getState(),
          countries: [...priorityCountries, ...filteredCountries],
        };

        ctx.patchState(newState);
      }),
      catchError((error: any) => {
        return throwError(error);
      }),
    );
  }
}
